// Generated by Framer (532f6a7)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import SectionHeaderFullBackgroundImage from "https://framerusercontent.com/modules/2TWrDbky9gNJx1ZvE7Yc/wsz3Ekm91uKfCgdvRFh1/qImcxLKu1.js";
const SectionHeaderFullBackgroundImageFonts = getFonts(SectionHeaderFullBackgroundImage);

const cycleOrder = ["wqYcyXpT9"];

const variantClassNames = {wqYcyXpT9: "framer-v-1rdxj26"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "wqYcyXpT9", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "wqYcyXpT9", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-IdDhv", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1rdxj26", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"wqYcyXpT9"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}><motion.div className={"framer-74cp3h-container"} layoutDependency={layoutDependency} layoutId={"Tn3EqJjoa-container"} transformTemplate={transformTemplate} transition={transition}><SectionHeaderFullBackgroundImage height={"100%"} id={"Tn3EqJjoa"} layoutId={"Tn3EqJjoa"} style={{width: "100%"}} variant={"KgecItUMd"} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-IdDhv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IdDhv .framer-1dc54dx { display: block; }", ".framer-IdDhv .framer-1rdxj26 { height: 200px; position: relative; width: 200px; }", ".framer-IdDhv .framer-74cp3h-container { flex: none; height: auto; position: absolute; right: -1000px; top: 15%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerjsyWtyxKO: React.ComponentType<Props> = withCSS(Component, css, "framer-IdDhv") as typeof Component;
export default FramerjsyWtyxKO;

FramerjsyWtyxKO.displayName = "Header 4";

FramerjsyWtyxKO.defaultProps = {height: 200, width: 200};

addFonts(FramerjsyWtyxKO, [...SectionHeaderFullBackgroundImageFonts])